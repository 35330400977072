import React from 'react'
import styled from 'styled-components'
import { GetListingsOptionsType } from '@services/repliers/get-listings.service'
import { FlexDiv } from 'design-system/FlexDiv'
import searchIcon from '../assets/icons/search.svg'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import Toggle from 'react-toggle'
import { GeocoderInput, GeocoderLocation } from './GeocodeInput'
import Cookies from 'js-cookie'
const CurrencyFormat = require('react-currency-format')

// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 2.25em;
  font-family: Montserrat-Bold;
  margin: 0px;
  padding: 1.5em 0 0.75em;
  -webkit-font-smoothing: antialiased;
`
// Create a Wrapper component that'll render a <section> tag with some styles
const OuterBox = styled.div`
  color: white;
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
  height: 3em;
  padding: 5px 10px;
  background: rgb(28, 28, 28);
`

const MyInput = styled.input`
  box-sizing: border-box;

  font-size: 1em;
  font-family: CentraNo2-Book;
  font-weight: 500;
  padding: 0.7em;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  &:focus {
    outline: none;
  }
  box-sizing: border-box;
  border-radius: 0;
  border: 0;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  border-right: 1px solid gray;
  align-items: center;
  margin-right: 10px;
`
const IconDiv = styled.button`
  height: 100%;
  width: 25%;
  font-family: CentraNo2-Book;
  display: flex;

  background: white;
  border: 1px solid gray;
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`

const Mydiv = styled.div`
  height: 100%;
  flex: 0 0 22.5%;
  display: flex;
  background: #1b1b1b;
  border: 1px solid white;
  box-sizing: border-box;
  font-family: CentraNo2-Book;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  &:hover {
    background: white;
    border: 1px solid black;
    color: black;
  }
`

const MyDiv = styled.div`
  display: flex;
  flex: 0 0 52.5%;
  justify-content: space-evenly;
  align-items: center;
`
const MyDiv2 = styled.div`
  display: flex;
  flex: 0 0 37.5%;
  justify-content: center;
  align-items: center;
`
const MyDiv3 = styled.div`
  display: flex;
  width: 50%;

  align-items: center;
`

const InfoDiv = styled.div`
  display: flex;
  height: 2em;
  margin-top: 1em;
`

const TitleH1 = styled.h1`
  font-family: Montserrat-Bold;
  -webkit-font-smoothing: antialiased;
  font-size: 2.5em;
  font-weight: ;
  font-stretch: normal;
  font-style: normal;
  color: #1c1c1c;
  padding: 1em 0 0;
`

const NavItemButton = styled.button`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  cursor: pointer;
  transition: filter 300ms;
  &:focus {
    outline: 0;
    border: 1px solid black;
  }
  &:hover {
    filter: brightness(1.2);
  }
`
const NavItemButtonMobile = styled.button`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  cursor: pointer;
  transition: filter 300ms;
  &:focus {
    outline: 0;
    border: 1px solid black;
  }
  &:hover {
    filter: brightness(1.2);
  }
`

const Dropdown = styled.div`
  position: absolute;
  top: 50px;
  display: flex;
  width: 300px;
  background: white;
  flex-direction: column;
  left: 0;
  padding: 8px;
  z-index: 1000;
  border: 1px solid gray;
  border-top: 0;
`

enum DropdownCloseSignal {
  APPLY,
  RESET,
  CANCEL,
}

type SearchBarPropsType = {
  handleSearch: (options: GetListingsOptionsType) => void
  initialLocation?: GeocoderLocation
}

export const SearchBar: React.FunctionComponent<SearchBarPropsType> = props => {
  const [searchOptions, setSearchOptions] = React.useState<
    GetListingsOptionsType
  >({
    page: undefined,
    bounds: undefined,
    customBounds: undefined,
    class: undefined,
    minPrice: undefined,
    maxPrice: undefined,
    minBeds: undefined,
    maxBeds: undefined,
    minBaths: undefined,
    maxBaths: undefined,
    minSqft: undefined,
    maxSqft: undefined,
    listDate: undefined,
    keywords: undefined,
    propertyType: undefined,
    sortBy: 'createdOnDesc',
    type: undefined,
    hasImages: true,
    resultsPerPage: 30,
    label: undefined,
  })

  

  const [selectedLocation, setSelectedLocation] = React.useState<
    GeocoderLocation | undefined
  >(props.initialLocation)

  const [placeholderLabel, setPlaceholderLabel] = React.useState<string>()

  const [tempPriceOptions, setTempPriceOptions] = React.useState<{
    minPrice?: number
    maxPrice?: number
  }>({
    minPrice: undefined,
    maxPrice: undefined,
  })

  const [tempBathroomOptions, setTempBathroomOptions] = React.useState<{
    min?: number
    max?: number
  }>({
    min: undefined,
    max: undefined,
  })

  const [tempBedroomOptions, setTempBedroomOptions] = React.useState<{
    min?: number
    max?: number
  }>({
    min: undefined,
    max: undefined,
  })

  const [tempListDateOptions, setTempListDateOptions] = React.useState<string>()

  const [tempSqftOptions, setTempSqftOptions] = React.useState<{
    min?: number
    max?: number
  }>({
    min: undefined,
    max: undefined,
  })

  const [tempKeywordOptions, setTempKeywordOptions] = React.useState<string>()

  type ContractType = 'sale' | 'lease'
  type ClassType = 'residential' | 'commercial' | 'condo' | 'residential condo'

  type PropertyTypes = {
    Detached: string
    'Semi-Detached': string
    'Condo Apt': string
    'Condo Townhouse': string
    'Att/Row/Twnhouse': string
  }

  const propertyTypes: PropertyTypes = {
    Detached: 'Detached',
    'Semi-Detached': 'Semi-Detached',
    'Condo Apt': 'Condo',
    'Condo Townhouse': 'Condo Townhouse',
    'Att/Row/Twnhouse': 'Freehold Townhouse',
  }

  const propertyTypeKeys = Object.keys(propertyTypes)
  const [tempPropertyTypeOptions, setTempPropertyTypeOptions] = React.useState<
    string[]
  >([])

  const [locationLabel, setLocationLabel] = React.useState<string>()

  const [
    tempPropertyContractOption,
    setTempPropertyContractOption,
  ] = React.useState<ContractType | string>('sale')

  const [tempPropertyClassOption, setTempPropertyClassOption] = React.useState<
    ClassType
  >('residential')
  // >('residential' or 'condo')

  const handlePropertyTypeChange = (propertyTypeKey: string) => {
    setTempPropertyTypeOptions(
      tempPropertyTypeOptions.includes(propertyTypeKey)
        ? tempPropertyTypeOptions.filter(k => k !== propertyTypeKey)
        : tempPropertyTypeOptions.concat([propertyTypeKey]),
    )
  }

  React.useEffect(() => {
    const hasCondoSelected = tempPropertyTypeOptions.some(op =>
      op.includes('Condo'),
    )
    const hasOthersSelected = tempPropertyTypeOptions.some(
      op => !op.includes('Condo'),
    )
    let classType: ClassType = 'residential condo'
    if (hasCondoSelected && !hasOthersSelected) {
      classType = 'condo'
    } else if (!hasCondoSelected && hasOthersSelected) {
      classType = 'residential'
    }
    else if (hasCondoSelected && hasOthersSelected) {
      classType = 'residential condo'
    }
    setTempPropertyClassOption(classType)
  }, [tempPropertyTypeOptions])

  React.useEffect(() => {
    const locationLabelFromCookies = Cookies.get('locationLabel')
    if (locationLabelFromCookies) {
      setLocationLabel(JSON.parse(locationLabelFromCookies))
    }
  }, [])

  React.useEffect(() => {
    const searchOptionsFromCookies = Cookies.get('searchOptions-properties')

    if (searchOptionsFromCookies) {
      const s = JSON.parse(searchOptionsFromCookies)
      console.log('cookies', s)
      setTempPropertyContractOption(s.type)
      setTempPriceOptions({
        minPrice: s.minPrice,
        maxPrice: s.maxPrice,
      })
      setTempPropertyTypeOptions(s.propertyType ? s.propertyType : [])
      setTempBedroomOptions({
        min: s.minBeds,
        max: s.maxBeds,
      })
      setTempBathroomOptions({
        min: s.minBaths,
        max: s.maxBaths,
      })
      setTempSqftOptions({
        min: s.minSqft,
        max: s.maxSqft,
      })
      setTempListDateOptions(s.listDate)
      setTempKeywordOptions(s.keywords)

      const so = {
        page: searchOptions.page ? searchOptions.page : s.page,
        bounds: searchOptions.bounds ? searchOptions.bounds : s.bounds,
        class: searchOptions.class ? searchOptions.class : 'residential',
        customBounds: searchOptions.customBounds
          ? searchOptions.customBounds
          : s.customBounds,
        minPrice: searchOptions.minPrice ? searchOptions.minPrice : s.minPrice,
        maxPrice: searchOptions.maxPrice ? searchOptions.maxPrice : s.maxPrice,
        maxBeds: searchOptions.maxBeds ? searchOptions.maxBeds : s.maxBeds,
        minBeds: searchOptions.minBeds ? searchOptions.minBeds : s.minBeds,
        minBaths: searchOptions.minBaths ? searchOptions.minBaths : s.minBaths,
        maxBaths: searchOptions.maxBaths ? searchOptions.maxBaths : s.maxBaths,
        minSqft: searchOptions.minSqft ? searchOptions.minSqft : s.minSqft,
        maxSqft: searchOptions.maxSqft ? searchOptions.maxSqft : s.maxSqft,
        listDate: searchOptions.listDate ? searchOptions.listDate : s.listDate,
        keywords: searchOptions.keywords ? searchOptions.keywords : s.keywords,
        propertyType: s.propertyType ? s.propertyType : [],
        sortBy: searchOptions.sortBy ? searchOptions.sortBy : s.sortBy,
        type: searchOptions.type
          ? searchOptions.type
          : s.type
            ? s.type
            : 'sale',
        hasImages: searchOptions.hasImages
          ? searchOptions.hasImages
          : s.hasImages,
        resultsPerPage: searchOptions.resultsPerPage
          ? searchOptions.resultsPerPage
          : s.resultsPerPage,
        // label: searchOptions.label ? searchOptions.label : locationLabel,
        label: 'Pickering, ON, Canada',
      }

      props.handleSearch({
        ...so,
        ...(selectedLocation || undefined),
      })
      Cookies.set('searchOptions-properties', JSON.stringify(so))
    }
  }, [])

  React.useEffect(() => {
    const searchOptionsFromCookies = Cookies.get('searchOptions-properties')

    if (searchOptionsFromCookies) {
      const s = JSON.parse(searchOptionsFromCookies)
      const so = {
        page: s.page ? s.page : 1,
        bounds: searchOptions.bounds ? searchOptions.bounds : s.bounds,
        class: searchOptions.class ? searchOptions.class : 'residential condo',
        minPrice: searchOptions.minPrice ? searchOptions.minPrice : s.minPrice,
        maxPrice: searchOptions.maxPrice ? searchOptions.maxPrice : s.maxPrice,
        maxBeds: searchOptions.maxBeds ? searchOptions.maxBeds : s.maxBeds,
        minBeds: searchOptions.minBeds ? searchOptions.minBeds : s.minBeds,
        minBaths: searchOptions.minBaths ? searchOptions.minBaths : s.minBaths,
        maxBaths: searchOptions.maxBaths ? searchOptions.maxBaths : s.maxBaths,
        minSqft: searchOptions.minSqft ? searchOptions.minSqft : s.minSqft,
        maxSqft: searchOptions.maxSqft ? searchOptions.maxSqft : s.maxSqft,
        listDate: searchOptions.listDate ? searchOptions.listDate : s.listDate,
        keywords: searchOptions.keywords ? searchOptions.keywords : s.keywords,
        propertyType: searchOptions.propertyType
          ? searchOptions.propertyType
          : s.propertyType,
        sortBy: searchOptions.sortBy ? searchOptions.sortBy : s.sortBy,
        type: searchOptions.type
          ? searchOptions.type
          : s.type
            ? s.type
            : 'sale',
        hasImages: searchOptions.hasImages
          ? searchOptions.hasImages
          : s.hasImages,
        resultsPerPage: searchOptions.resultsPerPage
          ? searchOptions.resultsPerPage
          : s.resultsPerPage,
        	               // label: searchOptions.label ? searchOptions.label : locationLabel,
        label: 'Pickering, ON, Canada',

      }

      props.handleSearch({
        ...so,
        ...(selectedLocation || undefined),
      })
      Cookies.set('searchOptions-properties', JSON.stringify(so))
    } else {
      props.handleSearch({
        ...searchOptions,
        ...(selectedLocation || undefined),
      })
      Cookies.set('searchOptions-properties', JSON.stringify(searchOptions))
    }
    if (searchOptions.label) {
      // console.log(searchOptions.label)
      Cookies.set('locationLabel', JSON.stringify(searchOptions.label))
    }
  }, [searchOptions, selectedLocation])

  const handleSearchIconClick = (e?: React.MouseEvent | React.FormEvent) => {
    if (e) {
      e.preventDefault()
    }
    const searchOptionsFromCookies = Cookies.get('searchOptions-properties')

    if (searchOptionsFromCookies) {
      const s = JSON.parse(searchOptionsFromCookies)
      const so = {
        page: s.page ? s.page : 1,
        bounds: searchOptions.bounds ? searchOptions.bounds : s.bounds,
        class: searchOptions.class ? searchOptions.class : 'residential',
        minPrice: searchOptions.minPrice ? searchOptions.minPrice : s.minPrice,
        maxPrice: searchOptions.maxPrice ? searchOptions.maxPrice : s.maxPrice,
        maxBeds: searchOptions.maxBeds ? searchOptions.maxBeds : s.maxBeds,
        minBeds: searchOptions.minBeds ? searchOptions.minBeds : s.minBeds,
        minBaths: searchOptions.minBaths ? searchOptions.minBaths : s.minBaths,
        maxBaths: searchOptions.maxBaths ? searchOptions.maxBaths : s.maxBaths,
        minSqft: searchOptions.minSqft ? searchOptions.minSqft : s.minSqft,
        maxSqft: searchOptions.maxSqft ? searchOptions.maxSqft : s.maxSqft,
        listDate: searchOptions.listDate ? searchOptions.listDate : s.listDate,
        keywords: searchOptions.keywords ? searchOptions.keywords : s.keywords,
        propertyType: searchOptions.propertyType
          ? searchOptions.propertyType
          : s.propertyType,
        sortBy: searchOptions.sortBy ? searchOptions.sortBy : s.sortBy,
        type: searchOptions.type
          ? searchOptions.type
          : s.type
            ? s.type
            : 'sale',
        hasImages: searchOptions.hasImages
          ? searchOptions.hasImages
          : s.hasImages,
        resultsPerPage: searchOptions.resultsPerPage
          ? searchOptions.resultsPerPage
          : s.resultsPerPage,
        // label: searchOptions.label ? searchOptions.label : locationLabel,
        	                // label: searchOptions.label ? searchOptions.label : locationLabel,
        label: 'Toronto, ON',

      }

      props.handleSearch({
        ...so,
        ...(selectedLocation || undefined),
      })
      Cookies.set('searchOptions-properties', JSON.stringify(so))
    } else {
      props.handleSearch({
        ...searchOptions,
        ...(selectedLocation || undefined),
      })
      Cookies.set('searchOptions-properties', JSON.stringify(searchOptions))
    }
    if (searchOptions.label) {
      // console.log(searchOptions.label)
      Cookies.set('locationLabel', JSON.stringify(searchOptions.label))
    }
  }

  type DropdownKeys =
    | 'more'
    | 'rooms'
    | 'price'
    | 'propertyType'
    | 'mobileInformation'
    | undefined

  const [openDropdown, setOpenDropdownInner] = React.useState<DropdownKeys>()

  const setOpenDropdown = (key: DropdownKeys, signal?: DropdownCloseSignal) => {
    if (key && key !== openDropdown) {
      setOpenDropdown(openDropdown, DropdownCloseSignal.CANCEL)
    }

    const closing = key === openDropdown
    if (closing) {
      let cookies = Cookies.get('searchOptions-properties')
      let parsedCookies = JSON.parse(cookies)
      const objCookies = {
        page: parsedCookies.page ? parsedCookies.page : 1,
        bounds: searchOptions.bounds ? searchOptions.bounds : parsedCookies.bounds,
        class: searchOptions.class ? searchOptions.class : 'residential',
        minPrice: searchOptions.minPrice ? searchOptions.minPrice : parsedCookies.minPrice,
        maxPrice: searchOptions.maxPrice ? searchOptions.maxPrice : parsedCookies.maxPrice,
        maxBeds: searchOptions.maxBeds ? searchOptions.maxBeds : parsedCookies.maxBeds,
        minBeds: searchOptions.minBeds ? searchOptions.minBeds : parsedCookies.minBeds,
        minBaths: searchOptions.minBaths ? searchOptions.minBaths : parsedCookies.minBaths,
        maxBaths: searchOptions.maxBaths ? searchOptions.maxBaths : parsedCookies.maxBaths,
        minSqft: searchOptions.minSqft ? searchOptions.minSqft : parsedCookies.minSqft,
        maxSqft: searchOptions.maxSqft ? searchOptions.maxSqft : parsedCookies.maxSqft,
        listDate: searchOptions.listDate ? searchOptions.listDate : parsedCookies.listDate,
        keywords: searchOptions.keywords ? searchOptions.keywords : parsedCookies.keywords,
        propertyType: searchOptions.propertyType
          ? searchOptions.propertyType
          : parsedCookies.propertyType,
        sortBy: searchOptions.sortBy ? searchOptions.sortBy : parsedCookies.sortBy,
        type: searchOptions.type
          ? searchOptions.type
          : parsedCookies.type
            ? parsedCookies.type
            : 'sale',
        hasImages: searchOptions.hasImages
          ? searchOptions.hasImages
          : parsedCookies.hasImages,
        resultsPerPage: searchOptions.resultsPerPage
          ? searchOptions.resultsPerPage
          : parsedCookies.resultsPerPage,
        label: searchOptions.label ? searchOptions.label : locationLabel,
      }
      switch (signal) {
        case DropdownCloseSignal.APPLY:
          switch (key) {
            case 'price':
              setSearchOptions({
                ...searchOptions,
                minPrice: tempPriceOptions.minPrice,
                maxPrice: tempPriceOptions.maxPrice,
              })
              objCookies.minPrice = tempPriceOptions.minPrice
              objCookies.maxPrice = tempPriceOptions.maxPrice
              Cookies.set('searchOptions-properties', JSON.stringify(objCookies))
              break
            case 'rooms':
              setSearchOptions({
                ...searchOptions,
                minBeds:
                  tempBedroomOptions.min && tempBedroomOptions.min > 0
                    ? tempBedroomOptions.min
                    : undefined,
                maxBeds:
                  tempBedroomOptions.max && tempBedroomOptions.max > 0
                    ? tempBedroomOptions.max
                    : undefined,
                minBaths:
                  tempBathroomOptions.min && tempBathroomOptions.min > 0
                    ? tempBathroomOptions.min
                    : undefined,
                maxBaths:
                  tempBathroomOptions.max && tempBathroomOptions.max > 0
                    ? tempBathroomOptions.max
                    : undefined,
              })
              objCookies.maxBaths = tempBathroomOptions.max
              objCookies.minBaths = tempBathroomOptions.min
              objCookies.maxBeds = tempBedroomOptions.max
              objCookies.minBeds = tempBedroomOptions.max
              Cookies.set('searchOptions-properties', JSON.stringify(objCookies))
              break
            case 'propertyType':
              setSearchOptions({
                ...searchOptions,
                type: tempPropertyContractOption,
                class: tempPropertyClassOption,
                propertyType: tempPropertyTypeOptions,
              })
              objCookies.propertyType = tempPropertyTypeOptions.length
                ? tempPropertyTypeOptions
                : undefined
              objCookies.type = tempPropertyContractOption
              objCookies.class = tempPropertyClassOption
              Cookies.set('searchOptions-properties', JSON.stringify(objCookies))
              break
            case 'more':
              setSearchOptions({
                ...searchOptions,
                listDate: tempListDateOptions,
                keywords: tempKeywordOptions,
                maxSqft: tempSqftOptions.max,
                minSqft: tempSqftOptions.min,
              })
              objCookies.listDate = tempListDateOptions
              objCookies.keywords = tempKeywordOptions
              objCookies.maxSqft = tempSqftOptions.max
              objCookies.minSqft = tempSqftOptions.min
              Cookies.set('searchOptions-properties', JSON.stringify(objCookies))
              break
          }
          break
        case DropdownCloseSignal.CANCEL:
          switch (key) {
            case 'price':
              setTempPriceOptions({
                minPrice: searchOptions.minPrice,
                maxPrice: searchOptions.maxPrice,
              })
            case 'rooms':
              setTempBedroomOptions({
                min: searchOptions.minBeds,
                max: searchOptions.maxBeds,
              })
              setTempBathroomOptions({
                min: searchOptions.minBaths,
                max: searchOptions.maxBaths,
              })
              break
            case 'propertyType':
              setTempPropertyTypeOptions(searchOptions.propertyType || [])
              break
            case 'more':
              setTempListDateOptions(searchOptions.listDate)
              setTempKeywordOptions(searchOptions.keywords)
              break
          }
          break
        case DropdownCloseSignal.RESET:
          switch (key) {
            case 'price':
              setSearchOptions({
                ...searchOptions,
                minPrice: undefined,
                maxPrice: undefined,
              })
              setTempPriceOptions({
                minPrice: undefined,
                maxPrice: undefined,
              })
              objCookies.minPrice = undefined
              objCookies.maxPrice = undefined
              Cookies.set('searchOptions-properties', JSON.stringify(objCookies))
              break
            case 'rooms':
              setSearchOptions({
                ...searchOptions,
                minBeds: undefined,
                maxBeds: undefined,
                minBaths: undefined,
                maxBaths: undefined,
              })
              objCookies.maxBaths = undefined
              objCookies.minBaths = undefined
              objCookies.maxBeds = undefined
              objCookies.minBeds = undefined

              setTempBedroomOptions({
                min: undefined,
                max: undefined,
              })
              setTempBathroomOptions({
                min: undefined,
                max: undefined,
              })
              Cookies.set('searchOptions-properties', JSON.stringify(objCookies))
              break
            case 'propertyType':
              setSearchOptions({
                ...searchOptions,
                propertyType: undefined,
                type: 'sale',
                class: 'residential'
              })
              setTempPropertyTypeOptions([])
              objCookies.propertyType = undefined
              objCookies.type = 'sale'
              objCookies.class = 'residential'
              Cookies.set('searchOptions-properties', JSON.stringify(objCookies))
              break
            case 'more':
              setSearchOptions({
                ...searchOptions,
                listDate: undefined,
                keywords: undefined,
                maxSqft: undefined,
                minSqft: undefined,
              })
              setTempKeywordOptions(undefined)
              setTempListDateOptions(undefined)
              objCookies.listDate = undefined
              objCookies.keywords = undefined
              objCookies.maxSqft = undefined
              objCookies.minSqft = undefined
              Cookies.set('searchOptions-properties', JSON.stringify(objCookies))
              break
          }
          Cookies.set('searchOptions-properties', JSON.stringify(objCookies))
          break
      }
    }
    setOpenDropdownInner(closing ? undefined : key)
  }

  return (
    <div
      style={{
        margin: 'auto',
      }}
      className="searchBar"
    >
      <TitleH1>Search Listings in:</TitleH1>

      <OuterBox className="searchBarWrapperWrap">
        <MyDiv2 className="searchBarSec cityInput">
          <GeocoderInput
            placeholder={'Pickering, ON, Canada'}
            defaultValue={locationLabel}
            initialSelection={props.initialLocation}
            onNewSelection={setSelectedLocation}
            searchOptions={searchOptions}
            className="geoCoder"
          />
        </MyDiv2>

        <MyDiv className="desktopOnly">
          <FlexDiv
            size={1}
            justify="center"
            align="center"
            style={{
              position: 'relative',
              height: '100%',
            }}
            className="priceBtnWrapper"
          >
            <NavItemButton
              onClick={() => setOpenDropdown('price')}
              className="mobileMenuOption"
            >
              Price
            </NavItemButton>
            {openDropdown === 'price' && (
              <Dropdown className="priceDropdown">
                <FlexDiv
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <FlexDiv
                    style={
                      {
                        // padding: '0 5% 5%',
                      }
                    }
                  >
                    <FlexDiv>
                      <span
                        style={{
                          fontSize: '0.7em',
                          color: '#000',
                        }}
                      >
                        Min.
                      </span>
                      <CurrencyFormat
                        altTheme
                        value={tempPriceOptions.minPrice}
                        label="Min ($)"
                        style={{
                          padding: '5px',
                          marginTop: '5px',
                          border: '1.5px solid lightgrey',
                          borderRadius: '0px !important',
                          width: '100px',
                        }}
                        onValueChange={(v: any) => {
                          setTempPriceOptions({
                            maxPrice: tempPriceOptions.maxPrice,
                            minPrice: v.floatValue,
                          })
                        }}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </FlexDiv>
                    {/* <FlexDiv className="minusPriceSign">
                      <span
                        style={{
                          fontSize: '2em',
                          fontFamily: 'CentraNo2-Light',
                        }}
                      >
                        -
                      </span>
                    </FlexDiv> */}
                    <FlexDiv
                      style={{
                        flexDirection: 'column',
                        placeContent: 'center',
                      }}
                    >
                      <span
                        style={{
                          fontSize: '0.7em',
                          color: '#000',
                        }}
                      >
                        Max.
                      </span>
                      <CurrencyFormat
                        altTheme
                        value={tempPriceOptions.maxPrice}
                        label="Max ($)"
                        style={{
                          padding: '5px',
                          marginTop: '5px',
                          border: '1.5px solid lightgrey',
                          borderRadius: '0px !important',
                          width: '100px',
                        }}
                        onValueChange={(v: any) => {
                          setTempPriceOptions({
                            minPrice: tempPriceOptions.minPrice,
                            maxPrice: v.floatValue,
                          })
                        }}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </FlexDiv>
                  </FlexDiv>
                  <FlexDiv
                    style={{
                      alignSelf: 'flex-end',
                      padding: '5%',
                    }}
                  >
                    <FlexDiv className="applySec">
                      <input
                        type="submit"
                        value="Cancel"
                        onClick={() =>
                          setOpenDropdown('price', DropdownCloseSignal.CANCEL)
                        }
                        style={{
                          background: 'transparent',
                          color: 'grey',
                          border: 'none',
                          fontSize: '0.8em',
                          marginRight: '1em',
                        }}
                      />
                      <input
                        type="submit"
                        value="Reset"
                        onClick={() =>
                          setOpenDropdown('price', DropdownCloseSignal.RESET)
                        }
                        style={{
                          background: 'transparent',
                          color: 'grey',
                          border: 'none',
                          fontSize: '0.8em',
                          marginRight: '1em',
                        }}
                      />
                      <input
                        type="submit"
                        value="APPLY"
                        onClick={() =>
                          setOpenDropdown('price', DropdownCloseSignal.APPLY)
                        }
                        style={{
                          background: '#000',
                          color: '#fff',
                          fontFamily: 'CentraNo2-Medium',
                          letterSpacing: '1px',
                          padding: '10px 20px',
                          fontSize: '0.8em',
                        }}
                      />
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </Dropdown>
            )}
          </FlexDiv>
          <FlexDiv
            size={1}
            justify="center"
            align="center"
            style={{
              position: 'relative',
              height: '100%',
            }}
            className="priceBtnWrapper"
          >
            <NavItemButton
              onClick={() => setOpenDropdown('propertyType')}
              className="mobileMenuOption"
            >
              Property Type
            </NavItemButton>
            {openDropdown === 'propertyType' && (
              <Dropdown className="propertyDropdown">
                <div className="propertyWrapper">
                  <div
                    style={{
                      color: 'black',
                    }}
                  >
                    {propertyTypeKeys
                      .filter(k => k !== 'For Sale')
                      .map(k => (
                        <div key={`propertyType_${k}`}>
                          <FlexDiv container align="center">
                            <input
                              type="checkbox"
                              name={k}
                              value={k}
                              checked={tempPropertyTypeOptions.includes(k)}
                              onChange={e => handlePropertyTypeChange(k)}
                            />
                            <p
                              style={{
                                marginLeft: 8,
                                fontSize: '0.8em',
                                margin: '0.5em 0.25em',
                              }}
                            >
                              {propertyTypes[k as keyof PropertyTypes]}
                            </p>
                          </FlexDiv>
                        </div>
                      ))}
                  </div>
                  <div>
                    <FlexDiv container align="center">
                      <input
                        type="checkbox"
                        name="any"
                        value="any"
                        checked={tempPropertyTypeOptions.length === 0}
                        onChange={e =>
                          tempPropertyTypeOptions.length > 0 &&
                          setTempPropertyTypeOptions([])
                        }
                      />
                      <p
                        style={{
                          margin: '0.5em 0.25em',
                          color: 'black',
                          fontSize: '0.8em',
                        }}
                      >
                        All
                      </p>
                    </FlexDiv>
                  </div>
                  <div>
                    <FlexDiv
                      container
                      justify="space-between"
                      style={{
                        color: 'black',
                        margin: '0.5em 0',
                      }}
                    >
                      <p
                        style={{
                          margin: '0.5em 0.25em',
                          color: 'black',
                          fontSize: '0.8em',
                        }}
                      >
                        For sale
                      </p>
                      <Toggle
                        checked={tempPropertyContractOption === 'lease'}
                        onChange={() => {
                          if (tempPropertyContractOption === 'sale') {
                            setTempPropertyContractOption('lease')
                          } else {
                            setTempPropertyContractOption('sale')
                          }
                        }}
                        icons={false}
                      />
                      <p
                        style={{
                          margin: '0.5em 0.25em',
                          color: 'black',
                          fontSize: '0.8em',
                        }}
                      >
                        For lease
                      </p>
                    </FlexDiv>
                  </div>
                  <div>
                    <FlexDiv
                      container
                      justify="space-between"
                      style={{
                        color: 'black',
                        marginBottom: '0.5em',
                        marginTop: '0.5em',
                      }}
                    >
                      <p
                        style={{
                          margin: '0.5em 0.25em',
                          color: 'black',
                          fontSize: '0.8em',
                        }}
                      >
                        Residential
                      </p>
                      <Toggle
                        checked={tempPropertyClassOption === 'commercial'}
                        onChange={() => {
                          if (tempPropertyClassOption === 'residential') {
                            setTempPropertyClassOption('commercial')
                          } else {
                            setTempPropertyClassOption('residential')
                          }
                        }}
                        icons={false}
                      />
                      <p
                        style={{
                          margin: '0.5em 0.25em',
                          color: 'black',
                          fontSize: '0.8em',
                        }}
                      >
                        Commercial
                      </p>
                    </FlexDiv>
                  </div>

                  <FlexDiv
                    style={{
                      alignSelf: 'flex-end',
                    }}
                  >
                    <FlexDiv justify="flex-end" className="filterSaveWrapper">
                      <input
                        type="submit"
                        value="Cancel"
                        onClick={() =>
                          setOpenDropdown(
                            'propertyType',
                            DropdownCloseSignal.CANCEL,
                          )
                        }
                        style={{
                          background: 'transparent',
                          color: 'grey',
                          border: 'none',
                          fontSize: '0.8em',
                          marginRight: '1em',
                        }}
                      />
                      <input
                        type="submit"
                        value="Reset"
                        onClick={() =>
                          setOpenDropdown(
                            'propertyType',
                            DropdownCloseSignal.RESET,
                          )
                        }
                        style={{
                          background: 'transparent',
                          color: 'grey',
                          border: 'none',
                          fontSize: '0.8em',
                          marginRight: '1em',
                        }}
                      />
                      <input
                        type="submit"
                        name="submitPT"
                        value="Apply"
                        onClick={() =>
                          setOpenDropdown(
                            'propertyType',
                            DropdownCloseSignal.APPLY,
                          )
                        }
                        style={{
                          background: '#000',
                          color: '#fff',
                          fontFamily: 'CentraNo2-Medium',
                          letterSpacing: '1px',
                          padding: '10px 20px',
                          fontSize: '0.8em',
                        }}
                      />
                    </FlexDiv>
                  </FlexDiv>
                </div>
              </Dropdown>
            )}
          </FlexDiv>
          <FlexDiv
            size={1}
            justify="center"
            align="center"
            style={{
              position: 'relative',
              height: '100%',
            }}
            className="priceBtnWrapper"
          >
            <NavItemButton onClick={() => setOpenDropdown('rooms')}>
              Rooms
            </NavItemButton>
            {openDropdown === 'rooms' && (
              <Dropdown className="roomsDropdown">
                <div>
                  <div
                    style={{
                      marginBottom: 16,
                      color: 'black',
                    }}
                  >
                    <FlexDiv container>
                      <FlexDiv align="center" justify="center">
                        <h3
                          style={{
                            margin: 0,
                            fontSize: '0.9em',
                          }}
                        >
                          Beds
                        </h3>
                      </FlexDiv>

                      <FlexDiv justify="center" align="center">
                        <input
                          type="button"
                          value="-"
                          name="minBeds"
                          onClick={() =>
                            setTempBedroomOptions({
                              ...tempBedroomOptions,
                              min: Math.max(
                                (tempBedroomOptions.min || 0) - 1,
                                0,
                              ),
                            })
                          }
                          style={{
                            height: 27,
                            margin: 0,
                            padding: 3,
                            background: 'transparent',
                            borderRadius: 0,
                            border: '1px solid',
                          }}
                        />
                        <h1
                          style={{
                            margin: 0,
                            marginLeft: 8,
                            marginRight: 8,
                            fontSize: '1.5em',
                          }}
                        >
                          {tempBedroomOptions.min || 0}
                        </h1>
                        <input
                          type="button"
                          value="+"
                          name="addBeds"
                          onClick={() =>
                            setTempBedroomOptions({
                              ...tempBedroomOptions,
                              min: Math.min(
                                (tempBedroomOptions.min || 0) + 1,
                                tempBedroomOptions.max || 0,
                              ),
                            })
                          }
                          style={{
                            height: 27,
                            margin: 0,
                            padding: 3,
                            background: 'transparent',
                            borderRadius: 0,
                            border: '1px solid',
                          }}
                        />
                      </FlexDiv>
                      <FlexDiv justify="center" align="center">
                        <input
                          type="button"
                          value="-"
                          name="maxBeds"
                          onClick={() =>
                            setTempBedroomOptions({
                              ...tempBedroomOptions,
                              max: Math.max(
                                Math.max((tempBedroomOptions.max || 0) - 1, 0),
                                tempBedroomOptions.min || 0,
                              ),
                            })
                          }
                          style={{
                            height: 27,
                            margin: 0,
                            padding: 3,
                            background: 'transparent',
                            borderRadius: 0,
                            border: '1px solid',
                          }}
                        />
                        <h1
                          style={{
                            margin: 0,
                            marginLeft: 8,
                            marginRight: 8,
                            fontSize: '1.5em',
                          }}
                        >
                          {tempBedroomOptions.max || 0}
                        </h1>
                        <input
                          type="button"
                          value="+"
                          name="addBeds"
                          onClick={() =>
                            setTempBedroomOptions({
                              ...tempBedroomOptions,
                              max: (tempBedroomOptions.max || 0) + 1,
                            })
                          }
                          style={{
                            height: 27,
                            margin: 0,
                            padding: 3,
                            background: 'transparent',
                            borderRadius: 0,
                            border: '1px solid',
                          }}
                        />
                      </FlexDiv>
                    </FlexDiv>

                    <FlexDiv container>
                      <FlexDiv align="center" justify="center">
                        <h3
                          style={{
                            margin: 0,
                            fontSize: '0.9em',
                          }}
                        >
                          Baths
                        </h3>
                      </FlexDiv>
                      <FlexDiv justify="center" align="center">
                        <input
                          type="button"
                          value="-"
                          name="minBaths"
                          onClick={() =>
                            setTempBathroomOptions({
                              ...tempBathroomOptions,
                              min: Math.max(
                                (tempBathroomOptions.min || 0) - 1,
                                0,
                              ),
                            })
                          }
                          style={{
                            height: 27,
                            margin: 0,
                            padding: 3,
                            background: 'transparent',
                            borderRadius: 0,
                            border: '1px solid',
                          }}
                        />
                        <h1
                          style={{
                            margin: 0,
                            marginLeft: 8,
                            marginRight: 8,
                            fontSize: '1.5em',
                          }}
                        >
                          {tempBathroomOptions.min || 0}
                        </h1>
                        <input
                          type="button"
                          value="+"
                          name="addBaths"
                          onClick={() =>
                            setTempBathroomOptions({
                              ...tempBathroomOptions,
                              min: Math.min(
                                (tempBathroomOptions.min || 0) + 1,
                                tempBathroomOptions.max || 0,
                              ),
                            })
                          }
                          style={{
                            height: 27,
                            margin: 0,
                            padding: 3,
                            background: 'transparent',
                            borderRadius: 0,
                            border: '1px solid',
                          }}
                        />
                      </FlexDiv>
                      <FlexDiv justify="center" align="center">
                        <input
                          type="button"
                          value="-"
                          name="maxBaths"
                          onClick={() =>
                            setTempBathroomOptions({
                              ...tempBathroomOptions,
                              max: Math.max(
                                (tempBathroomOptions.max || 0) - 1,
                                tempBathroomOptions.min || 0,
                              ),
                            })
                          }
                          style={{
                            height: 27,
                            margin: 0,
                            padding: 3,
                            background: 'transparent',
                            borderRadius: 0,
                            border: '1px solid',
                          }}
                        />
                        <h1
                          style={{
                            margin: 0,
                            marginLeft: 8,
                            marginRight: 8,
                            fontSize: '1.5em',
                          }}
                        >
                          {tempBathroomOptions.max || 0}
                        </h1>
                        <input
                          type="button"
                          value="+"
                          name="addBaths"
                          onClick={() =>
                            setTempBathroomOptions({
                              ...tempBathroomOptions,
                              max: (tempBathroomOptions.max || 0) + 1,
                            })
                          }
                          style={{
                            height: 27,
                            margin: 0,
                            padding: 3,
                            background: 'transparent',
                            borderRadius: 0,
                            border: '1px solid',
                          }}
                        />
                      </FlexDiv>
                    </FlexDiv>
                  </div>
                  <FlexDiv
                    justify="flex-end"
                    style={{
                      margin: 8,
                    }}
                  >
                    <input
                      type="submit"
                      name="cancelBeds"
                      value="Cancel"
                      onClick={() =>
                        setOpenDropdown('rooms', DropdownCloseSignal.CANCEL)
                      }
                      style={{
                        background: 'transparent',
                        color: 'grey',
                        border: 'none',
                        fontSize: '0.8em',
                        marginRight: '1em',
                      }}
                    />
                    <input
                      type="submit"
                      value="Reset"
                      onClick={() =>
                        setOpenDropdown('rooms', DropdownCloseSignal.RESET)
                      }
                      style={{
                        background: 'transparent',
                        color: 'grey',
                        border: 'none',
                        fontSize: '0.8em',
                        marginRight: '1em',
                      }}
                    />
                    <input
                      type="submit"
                      value="Apply"
                      onClick={() =>
                        setOpenDropdown('rooms', DropdownCloseSignal.APPLY)
                      }
                      style={{
                        background: '#000',
                        color: '#fff',
                        fontFamily: 'CentraNo2-Medium',
                        letterSpacing: '1px',
                        padding: '10px 20px',
                        fontSize: '0.8em',
                      }}
                    />
                  </FlexDiv>
                </div>
              </Dropdown>
            )}
          </FlexDiv>
          <FlexDiv
            size={1}
            justify="center"
            align="center"
            style={{
              position: 'relative',
              height: '100%',
            }}
            className="priceBtnWrapper"
          >
            <NavItemButton onClick={() => setOpenDropdown('more')}>
              More
            </NavItemButton>
            {openDropdown === 'more' && (
              <Dropdown className="moreDropdown">
                <div>
                  <div
                    style={{
                      color: 'black',
                    }}
                  >
                    <FlexDiv
                      vert
                      style={{
                        marginBottom: 10,
                      }}
                    >
                      <h3>Min Sqft</h3>
                      <input
                        type="number"
                        name="minFt"
                        placeholder="min sqft"
                        defaultValue={tempSqftOptions.min}
                        onChange={e => {
                          setTempSqftOptions({
                            ...tempSqftOptions,
                            min: parseInt(e.target.value, 10),
                          })
                        }}
                      />
                      <h3>Max Sqft</h3>
                      <input
                        type="number"
                        name="maxFt"
                        placeholder="max sqft"
                        defaultValue={tempSqftOptions.max}
                        onChange={e => {
                          setTempSqftOptions({
                            ...tempSqftOptions,
                            max: parseInt(e.target.value, 10),
                          })
                        }}
                      />
                      <h3>Listed Since</h3>
                      <input
                        type="date"
                        name="listDate"
                        onChange={e => setTempListDateOptions(e.target.value)}
                      />

                      {/* <h3>Keywords</h3>
                      <input
                        type="textarea"
                        name="keywords"
                        defaultValue={searchOptions.keywords}
                        onChange={e => setTempKeywordOptions(e.target.value)}
                      /> */}
                    </FlexDiv>
                  </div>
                  <FlexDiv justify="flex-end">
                    <input
                      type="submit"
                      value="Cancel"
                      style={{
                        background: 'transparent',
                        color: 'grey',
                        border: 'none',
                        fontSize: '0.8em',
                        marginRight: '1em',
                      }}
                      onClick={() =>
                        setOpenDropdown('more', DropdownCloseSignal.CANCEL)
                      }
                    />
                    <input
                      type="submit"
                      value="Reset"
                      style={{
                        background: 'transparent',
                        color: 'grey',
                        border: 'none',
                        fontSize: '0.8em',
                        marginRight: '1em',
                      }}
                      onClick={() =>
                        setOpenDropdown('more', DropdownCloseSignal.RESET)
                      }
                    />
                    <input
                      type="submit"
                      value="Apply"
                      onClick={() =>
                        setOpenDropdown('more', DropdownCloseSignal.APPLY)
                      }
                      style={{
                        background: '#000',
                        color: '#fff',
                        fontFamily: 'CentraNo2-Medium',
                        letterSpacing: '1px',
                        padding: '10px 20px',
                        fontSize: '0.8em',
                      }}
                    />
                  </FlexDiv>
                </div>
              </Dropdown>
            )}
          </FlexDiv>
        </MyDiv>
        <IconDiv
          onClick={handleSearchIconClick}
          className="searchIconButton desktopOnly"
        >
          <span
            style={{
              marginRight: 10,
            }}
          >
            {' '}
            Search{' '}
          </span>
          <img
            src={searchIcon}
            alt="icon"
            style={{
              height: '40%',
            }}
          />
        </IconDiv>
        <IconDiv
          onClick={handleSearchIconClick}
          className="searchIconButton mobileOnlyOD mobileSearch"
        >
          <span
            style={{
              marginRight: 10,
            }}
          >
            {' '}
          </span>
          <img
            src={searchIcon}
            alt="icon"
            style={{
              height: '40%',
            }}
          />
        </IconDiv>
      </OuterBox>
      <MyDiv
        className="mobileOnlyOD"
        style={{
          flex: 1,
          position: 'relative',
        }}
      >
        <FlexDiv
          size={1}
          justify="center"
          align="center"
          style={{
            height: 40,
            color: 'black',
          }}
        >
          <NavItemButtonMobile
            onClick={() => setOpenDropdown('price')}
            className="MobileMenuSearchItem price"
          >
            Price
          </NavItemButtonMobile>
          {openDropdown === 'price' && (
            <Dropdown className="priceDropdown">
              <FlexDiv
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <FlexDiv
                  style={
                    {
                      // padding: '0 5% 5%',
                    }
                  }
                >
                  <FlexDiv>
                    <span
                      style={{
                        fontSize: '0.7em',
                        color: '#000',
                      }}
                    >
                      Min.
                    </span>
                    <CurrencyFormat
                      altTheme
                      value={tempPriceOptions.minPrice}
                      label="Min ($)"
                      style={{
                        padding: '5px',
                        marginTop: '5px',
                        border: '1.5px solid lightgrey',
                        borderRadius: '0px !important',
                        width: '100px',
                      }}
                      onValueChange={(v: any) => {
                        setTempPriceOptions({
                          maxPrice: tempPriceOptions.maxPrice,
                          minPrice: v.floatValue,
                        })
                      }}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </FlexDiv>
                  {/* <FlexDiv className="minusPriceSign">
                      <span
                        style={{
                          fontSize: '2em',
                          fontFamily: 'CentraNo2-Light',
                        }}
                      >
                        -
                      </span>
                    </FlexDiv> */}
                  <FlexDiv
                    style={{
                      flexDirection: 'column',
                      placeContent: 'center',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '0.7em',
                        color: '#000',
                      }}
                    >
                      Max.
                    </span>
                    <CurrencyFormat
                      altTheme
                      value={tempPriceOptions.maxPrice}
                      label="Max ($)"
                      style={{
                        padding: '5px',
                        marginTop: '5px',
                        border: '1.5px solid lightgrey',
                        borderRadius: '0px !important',
                        width: '100px',
                      }}
                      onValueChange={(v: any) => {
                        setTempPriceOptions({
                          minPrice: tempPriceOptions.minPrice,
                          maxPrice: v.floatValue,
                        })
                      }}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </FlexDiv>
                </FlexDiv>
                <FlexDiv
                  style={{
                    alignSelf: 'flex-end',
                    padding: '5%',
                  }}
                >
                  <FlexDiv className="applySec" style={{ justifyContent: 'flex-end'}}>
                    <input
                      type="submit"
                      value="Cancel"
                      onClick={() =>
                        setOpenDropdown('price', DropdownCloseSignal.CANCEL)
                      }
                      style={{
                        background: 'transparent',
                        color: 'grey',
                        border: 'none',
                        fontSize: '0.8em',
                        marginRight: '1em',
                      }}
                    />
                    <input
                      type="submit"
                      value="Reset"
                      onClick={() =>
                        setOpenDropdown('price', DropdownCloseSignal.RESET)
                      }
                      style={{
                        background: 'transparent',
                        color: 'grey',
                        border: 'none',
                        fontSize: '0.8em',
                        marginRight: '1em',
                      }}
                    />
                    <input
                      type="submit"
                      value="APPLY"
                      onClick={() =>
                        setOpenDropdown('price', DropdownCloseSignal.APPLY)
                      }
                      style={{
                        background: '#000',
                        color: '#fff',
                        fontFamily: 'CentraNo2-Medium',
                        letterSpacing: '1px',
                        padding: '10px 20px',
                        fontSize: '0.8em',
                      }}
                    />
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </Dropdown>
          )}
        </FlexDiv>
        <FlexDiv
          size={1}
          justify="center"
          align="center"
          style={{
            height: 40,
          }}
        >
          <NavItemButtonMobile
            onClick={() => setOpenDropdown('propertyType')}
            className="MobileMenuSearchItem"
          >
            Property Type
          </NavItemButtonMobile>
          {openDropdown === 'propertyType' && (
            <Dropdown className="propertyDropdown">
              <div className="propertyWrapper">
                <div
                  style={{
                    color: 'black',
                  }}
                >
                  {propertyTypeKeys
                    .filter(k => k !== 'For Sale')
                    .map(k => (
                      <div key={`propertyType_${k}`}>
                        <FlexDiv container align="center">
                          <input
                            type="checkbox"
                            name={k}
                            value={k}
                            checked={tempPropertyTypeOptions.includes(k)}
                            onChange={e => handlePropertyTypeChange(k)}
                          />
                          <p
                            style={{
                              marginLeft: 8,
                              fontSize: '0.8em',
                              margin: '0.5em 0.25em',
                            }}
                          >
                            {propertyTypes[k as keyof PropertyTypes]}
                          </p>
                        </FlexDiv>
                      </div>
                    ))}
                </div>
                <div>
                  <FlexDiv container align="center">
                    <input
                      type="checkbox"
                      name="any"
                      value="any"
                      checked={tempPropertyTypeOptions.length === 0}
                      onChange={e =>
                        tempPropertyTypeOptions.length > 0 &&
                        setTempPropertyTypeOptions([])
                      }
                    />
                    <p
                      style={{
                        margin: '0.5em 0.25em',
                        color: 'black',
                        fontSize: '0.8em',
                      }}
                    >
                      All
                    </p>
                  </FlexDiv>
                </div>
                <div>
                  <FlexDiv
                    container
                    justify="space-between"
                    style={{
                      color: 'black',
                      margin: '0.5em 0',
                    }}
                  >
                    <p
                      style={{
                        margin: '0.5em 0.25em',
                        color: 'black',
                        fontSize: '0.8em',
                      }}
                    >
                      For sale
                    </p>
                    <Toggle
                      checked={tempPropertyContractOption === 'lease'}
                      onChange={() => {
                        if (tempPropertyContractOption === 'sale') {
                          setTempPropertyContractOption('lease')
                        } else {
                          setTempPropertyContractOption('sale')
                        }
                      }}
                      icons={false}
                    />
                    <p
                      style={{
                        margin: '0.5em 0.25em',
                        color: 'black',
                        fontSize: '0.8em',
                      }}
                    >
                      For lease
                    </p>
                  </FlexDiv>
                </div>
                <div>
                  <FlexDiv
                    container
                    justify="space-between"
                    style={{
                      color: 'black',
                      marginBottom: '0.5em',
                      marginTop: '0.5em',
                    }}
                  >
                    <p
                      style={{
                        margin: '0.5em 0.25em',
                        color: 'black',
                        fontSize: '0.8em',
                      }}
                    >
                      Residential
                    </p>
                    <Toggle
                      checked={tempPropertyClassOption === 'commercial'}
                      onChange={() => {
                        if (tempPropertyClassOption === 'residential') {
                          setTempPropertyClassOption('commercial')
                        } else {
                          setTempPropertyClassOption('residential')
                        }
                      }}
                      icons={false}
                    />
                    <p
                      style={{
                        margin: '0.5em 0.25em',
                        color: 'black',
                        fontSize: '0.8em',
                      }}
                    >
                      Commercial
                    </p>
                  </FlexDiv>
                </div>

                <FlexDiv
                  style={{
                    alignSelf: 'flex-end',
                  }}
                >
                  <FlexDiv justify="flex-end" className="filterSaveWrapper">
                    <input
                      type="submit"
                      value="Cancel"
                      onClick={() =>
                        setOpenDropdown(
                          'propertyType',
                          DropdownCloseSignal.CANCEL,
                        )
                      }
                      style={{
                        background: 'transparent',
                        color: 'grey',
                        border: 'none',
                        fontSize: '0.8em',
                        marginRight: '1em',
                      }}
                    />
                    <input
                      type="submit"
                      value="Reset"
                      onClick={() =>
                        setOpenDropdown(
                          'propertyType',
                          DropdownCloseSignal.RESET,
                        )
                      }
                      style={{
                        background: 'transparent',
                        color: 'grey',
                        border: 'none',
                        fontSize: '0.8em',
                        marginRight: '1em',
                      }}
                    />
                    <input
                      type="submit"
                      name="submitPT"
                      value="Apply"
                      onClick={() =>
                        setOpenDropdown(
                          'propertyType',
                          DropdownCloseSignal.APPLY,
                        )
                      }
                      style={{
                        background: '#000',
                        color: '#fff',
                        fontFamily: 'CentraNo2-Medium',
                        letterSpacing: '1px',
                        padding: '10px 20px',
                        fontSize: '0.8em',
                      }}
                    />
                  </FlexDiv>
                </FlexDiv>
              </div>
            </Dropdown>
          )}
        </FlexDiv>
        <FlexDiv
          size={1}
          justify="center"
          align="center"
          style={{
            height: 40,
          }}
        >
          <NavItemButtonMobile
            onClick={() => setOpenDropdown('rooms')}
            className="MobileMenuSearchItem"
          >
            Rooms
          </NavItemButtonMobile>
          {openDropdown === 'rooms' && (
            <Dropdown className="roomsDropdown">
              <div>
                <div
                  style={{
                    marginBottom: 16,
                    color: 'black',
                  }}
                >
                  <FlexDiv container>
                    <FlexDiv align="center" justify="center">
                      <h3
                        style={{
                          margin: 0,
                          fontSize: '0.9em',
                        }}
                      >
                        Beds
                      </h3>
                    </FlexDiv>

                    <FlexDiv justify="center" align="center">
                      <input
                        type="button"
                        value="-"
                        name="minBeds"
                        onClick={() =>
                          setTempBedroomOptions({
                            ...tempBedroomOptions,
                            min: Math.max((tempBedroomOptions.min || 0) - 1, 0),
                          })
                        }
                        style={{
                          height: 27,
                          margin: 0,
                          padding: 3,
                          background: 'transparent',
                          borderRadius: 0,
                          border: '1px solid',
                        }}
                      />
                      <h1
                        style={{
                          margin: 0,
                          marginLeft: 8,
                          marginRight: 8,
                          fontSize: '1.5em',
                        }}
                      >
                        {tempBedroomOptions.min || 0}
                      </h1>
                      <input
                        type="button"
                        value="+"
                        name="addBeds"
                        onClick={() =>
                          setTempBedroomOptions({
                            ...tempBedroomOptions,
                            min: Math.min(
                              (tempBedroomOptions.min || 0) + 1,
                              tempBedroomOptions.max || 0,
                            ),
                          })
                        }
                        style={{
                          height: 27,
                          margin: 0,
                          padding: 3,
                          background: 'transparent',
                          borderRadius: 0,
                          border: '1px solid',
                        }}
                      />
                    </FlexDiv>
                    <FlexDiv justify="center" align="center">
                      <input
                        type="button"
                        value="-"
                        name="maxBeds"
                        onClick={() =>
                          setTempBedroomOptions({
                            ...tempBedroomOptions,
                            max: Math.max(
                              Math.max((tempBedroomOptions.max || 0) - 1, 0),
                              tempBedroomOptions.min || 0,
                            ),
                          })
                        }
                        style={{
                          height: 27,
                          margin: 0,
                          padding: 3,
                          background: 'transparent',
                          borderRadius: 0,
                          border: '1px solid',
                        }}
                      />
                      <h1
                        style={{
                          margin: 0,
                          marginLeft: 8,
                          marginRight: 8,
                          fontSize: '1.5em',
                        }}
                      >
                        {tempBedroomOptions.max || 0}
                      </h1>
                      <input
                        type="button"
                        value="+"
                        name="addBeds"
                        onClick={() =>
                          setTempBedroomOptions({
                            ...tempBedroomOptions,
                            max: (tempBedroomOptions.max || 0) + 1,
                          })
                        }
                        style={{
                          height: 27,
                          margin: 0,
                          padding: 3,
                          background: 'transparent',
                          borderRadius: 0,
                          border: '1px solid',
                        }}
                      />
                    </FlexDiv>
                  </FlexDiv>

                  <FlexDiv container>
                    <FlexDiv align="center" justify="center">
                      <h3
                        style={{
                          margin: 0,
                          fontSize: '0.9em',
                        }}
                      >
                        Baths
                      </h3>
                    </FlexDiv>
                    <FlexDiv justify="center" align="center">
                      <input
                        type="button"
                        value="-"
                        name="minBaths"
                        onClick={() =>
                          setTempBathroomOptions({
                            ...tempBathroomOptions,
                            min: Math.max(
                              (tempBathroomOptions.min || 0) - 1,
                              0,
                            ),
                          })
                        }
                        style={{
                          height: 27,
                          margin: 0,
                          padding: 3,
                          background: 'transparent',
                          borderRadius: 0,
                          border: '1px solid',
                        }}
                      />
                      <h1
                        style={{
                          margin: 0,
                          marginLeft: 8,
                          marginRight: 8,
                          fontSize: '1.5em',
                        }}
                      >
                        {tempBathroomOptions.min || 0}
                      </h1>
                      <input
                        type="button"
                        value="+"
                        name="addBaths"
                        onClick={() =>
                          setTempBathroomOptions({
                            ...tempBathroomOptions,
                            min: Math.min(
                              (tempBathroomOptions.min || 0) + 1,
                              tempBathroomOptions.max || 0,
                            ),
                          })
                        }
                        style={{
                          height: 27,
                          margin: 0,
                          padding: 3,
                          background: 'transparent',
                          borderRadius: 0,
                          border: '1px solid',
                        }}
                      />
                    </FlexDiv>
                    <FlexDiv justify="center" align="center">
                      <input
                        type="button"
                        value="-"
                        name="maxBaths"
                        onClick={() =>
                          setTempBathroomOptions({
                            ...tempBathroomOptions,
                            max: Math.max(
                              (tempBathroomOptions.max || 0) - 1,
                              tempBathroomOptions.min || 0,
                            ),
                          })
                        }
                        style={{
                          height: 27,
                          margin: 0,
                          padding: 3,
                          background: 'transparent',
                          borderRadius: 0,
                          border: '1px solid',
                        }}
                      />
                      <h1
                        style={{
                          margin: 0,
                          marginLeft: 8,
                          marginRight: 8,
                          fontSize: '1.5em',
                        }}
                      >
                        {tempBathroomOptions.max || 0}
                      </h1>
                      <input
                        type="button"
                        value="+"
                        name="addBaths"
                        onClick={() =>
                          setTempBathroomOptions({
                            ...tempBathroomOptions,
                            max: (tempBathroomOptions.max || 0) + 1,
                          })
                        }
                        style={{
                          height: 27,
                          margin: 0,
                          padding: 3,
                          background: 'transparent',
                          borderRadius: 0,
                          border: '1px solid',
                        }}
                      />
                    </FlexDiv>
                  </FlexDiv>
                </div>
                <FlexDiv
                  justify="flex-end"
                  style={{
                    margin: 8,
                  }}
                >
                  <input
                    type="submit"
                    name="cancelBeds"
                    value="Cancel"
                    onClick={() =>
                      setOpenDropdown('rooms', DropdownCloseSignal.CANCEL)
                    }
                    style={{
                      background: 'transparent',
                      color: 'grey',
                      border: 'none',
                      fontSize: '0.8em',
                      marginRight: '1em',
                    }}
                  />
                  <input
                    type="submit"
                    value="Reset"
                    onClick={() =>
                      setOpenDropdown('rooms', DropdownCloseSignal.RESET)
                    }
                    style={{
                      background: 'transparent',
                      color: 'grey',
                      border: 'none',
                      fontSize: '0.8em',
                      marginRight: '1em',
                    }}
                  />
                  <input
                    type="submit"
                    value="Apply"
                    onClick={() =>
                      setOpenDropdown('rooms', DropdownCloseSignal.APPLY)
                    }
                    style={{
                      background: '#000',
                      color: '#fff',
                      fontFamily: 'CentraNo2-Medium',
                      letterSpacing: '1px',
                      padding: '10px 20px',
                      fontSize: '0.8em',
                    }}
                  />
                </FlexDiv>
              </div>
            </Dropdown>
          )}
        </FlexDiv>
        <FlexDiv
          size={1}
          justify="center"
          align="center"
          style={{
            height: 40,
          }}
        >
          <NavItemButtonMobile
            onClick={() => setOpenDropdown('more')}
            className="MobileMenuSearchItem"
          >
            More
          </NavItemButtonMobile>
          {openDropdown === 'more' && (
            <Dropdown className="moreDropdown">
              <div>
                <div
                  style={{
                    color: 'black',
                  }}
                >
                  <FlexDiv
                    vert
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <h3>Min Sqft</h3>
                    <input
                      type="number"
                      name="minFt"
                      placeholder="min sqft"
                      defaultValue={tempSqftOptions.min}
                      onChange={e => {
                        setTempSqftOptions({
                          ...tempSqftOptions,
                          min: parseInt(e.target.value, 10),
                        })
                      }}
                    />
                    <h3>Max Sqft</h3>
                    <input
                      type="number"
                      name="maxFt"
                      placeholder="max sqft"
                      defaultValue={tempSqftOptions.max}
                      onChange={e => {
                        setTempSqftOptions({
                          ...tempSqftOptions,
                          max: parseInt(e.target.value, 10),
                        })
                      }}
                    />
                    <h3>Listed Since</h3>
                    <input
                      type="date"
                      name="listDate"
                      onChange={e => setTempListDateOptions(e.target.value)}
                    />

                    {/* <h3>Keywords</h3>
                      <input
                        type="textarea"
                        name="keywords"
                        defaultValue={searchOptions.keywords}
                        onChange={e => setTempKeywordOptions(e.target.value)}
                      /> */}
                  </FlexDiv>
                </div>
                <FlexDiv justify="flex-end">
                  <input
                    type="submit"
                    value="Cancel"
                    style={{
                      background: 'transparent',
                      color: 'grey',
                      border: 'none',
                      fontSize: '0.8em',
                      marginRight: '1em',
                    }}
                    onClick={() =>
                      setOpenDropdown('more', DropdownCloseSignal.CANCEL)
                    }
                  />
                  <input
                    type="submit"
                    value="Reset"
                    style={{
                      background: 'transparent',
                      color: 'grey',
                      border: 'none',
                      fontSize: '0.8em',
                      marginRight: '1em',
                    }}
                    onClick={() =>
                      setOpenDropdown('more', DropdownCloseSignal.RESET)
                    }
                  />
                  <input
                    type="submit"
                    value="Apply"
                    onClick={() =>
                      setOpenDropdown('more', DropdownCloseSignal.APPLY)
                    }
                    style={{
                      background: '#000',
                      color: '#fff',
                      fontFamily: 'CentraNo2-Medium',
                      letterSpacing: '1px',
                      padding: '10px 20px',
                      fontSize: '0.8em',
                    }}
                  />
                </FlexDiv>
              </div>
            </Dropdown>
          )}
        </FlexDiv>
      </MyDiv>
    </div>
  )
}
